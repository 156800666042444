import { chessSide } from 'chessgun/core';
import { CGStreamBoard } from 'chessgun/react';
import React, { useEffect, useMemo, useState } from 'react';
import { RightArrowIcon, TextButton } from 'shared/atoms';
import {
  getMsFromNow,
  getOpponentColor,
  getPlayerData,
  msToTime,
} from 'shared/helpers/_game';
import { useCountdown } from '@utils/hooks/_useCountdown';
import { useMobile } from 'shared/hooks/_useMobile.hook';
import { baseText, textColors } from 'shared/styles';
import { playerSide } from 'shared/types';
import styled from 'styled-components';

import {
  $Board,
  $ChessboardWrapper,
  $FullName,
  $Timer,
  $TournamentMobile,
} from './_styles';
import { IActiveBoardWidget } from './_types';
import { SmallBoardTimer } from '@components/atoms/notification/_smallBoardTimer';
import { BoardPlayer } from './fragments/_BoardPlayer';
import { getFormattedMove, getPlayersMsLeft } from '@utils/_ng_helpers';
import { getPlayersPreMoveMs } from '@utils/_ng_helpers';
import { ePhase } from '@store/context/_common.types';
import { useChessboardSettingsForBoard } from '@utils/hooks/_useChessboardSettingsForBoard.hook';
import { getMyColor } from '@utils/_getMyColor';

/**
 * Виджет активной доски пользователя
 * @param {string} playerUid - id игрока
 * @param {ILobbyContextGame} board - информация о доске
 * @param {IBoardData} boardData - информация о партии
 * @param {Function} goGameClick - обработчик нажатия на кнопку
 * @param {IBoardWidgetLocalization} translation - локализация
 */
export const ActiveBoardWidget: React.FC<IActiveBoardWidget> = ({
  playerUid,
  board,
  boardData,
  goGameClick,
  translation,
}) => {
  const isMobile = useMobile();

  const boardSettings = useChessboardSettingsForBoard();

  const [playerTurn, setPlayerTurn] = useState<chessSide>(chessSide.WHITE);

  const state = board.state;
  const publicExtra = state?.cfg?.publicExtra;
  const turns = state?.playingPh?.turns;
  const dateTimeOfRound = state?.cfg?.scheduledPhCfg?.start || null;
  const isTournamentGame =
    !!publicExtra?.tournament_id || !!boardData?.tournament?.id;

  const myColor = getMyColor(playerUid, state);

  const whitePlayer = useMemo(() => {
    if (!boardData?.white_player) return null;

    return boardData?.white_player;
  }, [boardData?.white_player]);

  const blackPlayer = useMemo(() => {
    if (!boardData?.black_player) return null;

    return boardData?.black_player;
  }, [boardData?.black_player]);

  const { whiteMsLeft, blackMsLeft } = useMemo(() => {
    if (boardData?.result) {
      const whiteMsLeft = boardData.white_ms_left;
      const blackMsLeft = boardData.black_ms_left;
      return { whiteMsLeft, blackMsLeft };
    }

    return getPlayersMsLeft(state);
  }, [state, boardData]);

  const { whitePreMoveMsLeft, blackPreMoveMsLeft } = useMemo(() => {
    return getPlayersPreMoveMs({
      currentMoveData: board?.state?.playingPh?.currTurn,
      playingPhCfg: board?.state?.cfg?.playingPhCfg,
    });
  }, [board?.state?.cfg?.playingPhCfg, board?.state?.playingPh?.currTurn]);

  const { whiteTimeControlMs, blackTimeControlMs } = useMemo(() => {
    return {
      whiteTimeControlMs: board.state?.cfg?.playingPhCfg.wTc.init,
      blackTimeControlMs: board.state?.cfg?.playingPhCfg.bTc.init,
    };
  }, [board.state?.cfg?.playingPhCfg]);

  const { milliseconds, setMilliseconds, startTimer, stopTimer } = useCountdown(
    {
      timeInMilliseconds: 0,
      autostart: false,
    }
  );

  const localization = {
    go_game: 'Go to game',
    ...translation,
  };

  const moves = useMemo(() => {
    if (!turns?.length) return [];
    return turns.map((move) => getFormattedMove(move));
  }, [turns]);

  const showTimer = useMemo(() => {
    return (
      isTournamentGame && dateTimeOfRound && state?.currPh !== ePhase.PLAYING
    );
  }, [isTournamentGame, dateTimeOfRound, state?.currPh]);

  useEffect(() => {
    if (!!dateTimeOfRound && isTournamentGame) {
      const msLeft = getMsFromNow(dateTimeOfRound);

      if (msLeft > 0) {
        setMilliseconds(msLeft);
      }
    }
  }, [isTournamentGame, dateTimeOfRound]);

  useEffect(() => {
    if (milliseconds) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [milliseconds]);

  useEffect(() => {
    const lastMove = moves[moves.length - 1];
    if (lastMove) {
      setPlayerTurn(lastMove.is_white_move ? chessSide.BLACK : chessSide.WHITE);
    }
  }, [moves]);

  if (showTimer && isMobile) {
    return (
      <$TournamentMobile onClick={goGameClick}>
        <$FullName>
          {whitePlayer && blackPlayer
            ? getPlayerData({
                playerType: 'opponent',
                myColor,
                whiteData: whitePlayer,
                blackData: blackPlayer,
              }).full_name || 'Anonymous'
            : null}
        </$FullName>

        <$Timer>{msToTime(milliseconds)}</$Timer>

        <$TextButton
          icon={RightArrowIcon}
          iconPosition="right"
          className="go_game_button"
        >
          {localization.go_game}
        </$TextButton>
      </$TournamentMobile>
    );
  }

  return (
    <$Board onClick={goGameClick}>
      <BoardPlayer
        player={
          whitePlayer && blackPlayer
            ? getPlayerData({
                playerType: 'opponent',
                myColor,
                whiteData: whitePlayer,
                blackData: blackPlayer,
              })
            : null
        }
        msLeft={getPlayerData({
          playerType: 'opponent',
          myColor,
          whiteData: whiteMsLeft,
          blackData: blackMsLeft,
        })}
        side={playerSide.TOP}
        translation={translation}
        currPh={state?.currPh}
        playerTurn={playerTurn}
        playerColor={getOpponentColor(myColor)}
        whitePreMoveMsLeft={whitePreMoveMsLeft}
        blackPreMoveMsLeft={blackPreMoveMsLeft}
        timeControlMs={getPlayerData({
          playerType: 'opponent',
          myColor,
          whiteData: whiteTimeControlMs,
          blackData: blackTimeControlMs,
        })}
      />

      <$ChessboardWrapper>
        {showTimer && <SmallBoardTimer time={msToTime(milliseconds)} />}
        <CGStreamBoard
          {...boardSettings}
          chessboardSize={160}
          history={moves.map((i) => ({
            fen: i.fen,
            lan: i.long_san,
            moveNumber: i.move_number,
          }))}
        />
      </$ChessboardWrapper>

      <BoardPlayer
        player={
          whitePlayer && blackPlayer
            ? getPlayerData({
                playerType: 'me',
                myColor,
                whiteData: whitePlayer,
                blackData: blackPlayer,
              })
            : null
        }
        msLeft={getPlayerData({
          playerType: 'me',
          myColor,
          whiteData: whiteMsLeft,
          blackData: blackMsLeft,
        })}
        side={playerSide.BOTTOM}
        translation={translation}
        buttonClassName="go_game_button"
        currPh={state?.currPh}
        playerColor={myColor}
        playerTurn={playerTurn}
        whitePreMoveMsLeft={whitePreMoveMsLeft}
        blackPreMoveMsLeft={blackPreMoveMsLeft}
        timeControlMs={getPlayerData({
          playerType: 'me',
          myColor,
          whiteData: whiteTimeControlMs,
          blackData: blackTimeControlMs,
        })}
      />
    </$Board>
  );
};

const $TextButton = styled(TextButton)`
  background: transparent;

  p {
    ${baseText({
      color: textColors['brown'],
      fontSize: '14px',
    })}
  }

  div {
    width: 6px;
    height: 12px;
    margin-top: 2px;
    margin-left: 4px;
  }
`;
