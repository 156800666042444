import { useCallback, useMemo } from 'react';
import Link from 'next/link';

import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { useApplicationContext } from '@application';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { eAccessLevel } from 'shared/types';
import { eFideIdStatus } from '@types';
import { ePopupPaths, routesConstants } from '@constants';
import { openPopup } from '@utils/_router';
import {
  ButtonClickEventParams,
  ButtonType,
} from '@chessarena/components/lib/general/Button/types';
import {
  FooterPlayChessColorTypes,
  FooterPlayChessIconTypes,
} from '@chessarena/components/lib/layout/FooterPlayChess/types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export const useCustomFooterDataPlayChess = () => {
  const { localization: l } = useApplicationContext();

  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const fideIdStatus = useFideIdStatus();
  const accessLevel = userData?.access_level;

  const renderLink = (params: RenderLinkParams) => {
    const { link, children } = params;

    return (
      <Link
        href={link}
        onClick={() => gtmPush({ event: GTM_EVENTS.LEAR_MORE_CLICK })}
      >
        {children}
      </Link>
    );
  };

  const learnMore = useMemo(
    () => ({
      link: routesConstants.PRICING,
      text: l?.footer.play_chess.learn_more,
    }),
    [l]
  );

  const action = useMemo(() => {
    if (!logged)
      return {
        id: 'join',
        type: 'primary' as ButtonType,
        label: l?.footer.play_chess.actions.join,
      };

    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
        return {
          id: 'fideId',
          type: 'capri' as ButtonType,
          label: l?.footer.play_chess.actions.fide_id,
        };
      return undefined;
    }

    return {
      id: 'upgrade',
      type: 'yellow' as ButtonType,
      label: l?.footer.play_chess.actions.upgrade,
      link: routesConstants.PRICING,
    };
  }, [l, logged, accessLevel, fideIdStatus]);

  const items = useMemo(() => {
    return [
      {
        id: '1',
        icon: 'pawnArrow' as FooterPlayChessIconTypes,
        color: 'capri' as FooterPlayChessColorTypes,
      },
      {
        id: '2',
        text: l?.footer.play_chess.description.p1,
      },
      {
        id: '3',
        icon: 'handsOnHead' as FooterPlayChessIconTypes,
        color: 'malachite' as FooterPlayChessColorTypes,
      },
      {
        id: '4',
        text: l?.footer.play_chess.description.p2,
      },
    ];
  }, [l]);

  const onFideIdClick = useCallback(() => {
    if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
      openPopup(`?popup=${ePopupPaths.FIDE}`);
  }, [fideIdStatus]);

  const onClick = ({ id }: ButtonClickEventParams) => {
    switch (id) {
      case 'join':
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
        break;
      case 'fideId':
        onFideIdClick();
        break;
      default:
        break;
    }
  };

  return {
    action,
    items,
    learnMore,
    renderLink,
    onClick,
  };
};
