import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Молния
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const LightningIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 13 22", fill: "none" },
    React.createElement("path", { d: "M9.48276 0L0 12.8966H5.68966L3.03448 22L12.8966 9.10345H7.2069L9.48276 0Z", fill: fill })));
