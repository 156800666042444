import dynamic from 'next/dynamic';
import React, { PropsWithChildren, memo } from 'react';

import Layout from '@chessarena/components/lib/layout/Layout';
import Main from '@chessarena/components/lib/layout/Main';

import { FooterWrapper, HeaderWrapper } from './fragments';
import { useToken, useUnsubscribeByTag } from './hooks';

const ConnectionIssuesWrapper = dynamic(
  () => import('./fragments').then((module) => module.ConnectionIssuesWrapper),
  { ssr: false }
);

const AppLayout: React.FC<PropsWithChildren> = memo(function AppLayout({
  children,
}) {
  useToken();
  useUnsubscribeByTag();

  return (
    <Layout>
      <HeaderWrapper />
      <Main>{children}</Main>
      <FooterWrapper />
      {/* <ActiveGamesNotificationWrapper /> */}
      <ConnectionIssuesWrapper />
    </Layout>
  );
});

export default AppLayout;
