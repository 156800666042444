import styled, { css } from 'styled-components';
import { baseRow, baseTransition, Game_P3, Header_text, textColors, } from '../../../../styles';
export const $Text = styled.p `
  color: ${textColors['brown']};
  ${baseTransition};

  ${({ size }) => size === 'big'
    ? Header_text(textColors['brown'])
    : Game_P3(textColors['brown'])}
`;
export const $Icon = styled.div `
  display: flex;

  ${({ size }) => {
    return size === 'big'
        ? css `
          width: 21px;
          height: 21px;
        `
        : css `
          width: 11px;
          height: 11px;
        `;
}}
`;
export const $A = styled.a `
  ${baseRow('flex-start')}
  text-decoration: none;
`;
export const $Button = styled.button `
  position: relative;
  border: none;
  background-color: inherit;
  cursor: pointer;

  ${baseRow('flex-start')}

  path {
    fill: ${textColors['brown']};
    ${baseTransition};
  }

  &:hover {
    ${$Text} {
      color: ${textColors['white']};
      transition: 0s;
    }

    path {
      fill: ${textColors['white']};
      transition: 0s;
    }
  }

  &:disabled {
    cursor: default;

    ${$Text} {
      color: ${textColors['brown_deep']};
    }

    path {
      fill: ${textColors['brown_deep']};
    }
  }

  ${({ iconPosition }) => {
    if (iconPosition === 'left') {
        return css `
        ${$Icon} {
          margin-right: 5px;
        }
      `;
    }
    return css `
      ${$Text} {
        order: 1;
      }

      ${$Icon} {
        order: 2;
        margin-left: 5px;
      }
    `;
}}
`;
export const $LinkButton = styled($Button) ``;
