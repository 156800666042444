import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { routesConstants } from '@constants';
import { ActiveBoardWidget } from './_activeBoardWidget';
import styles from './_active_games_notification.module.scss';
import { ePhase } from '@store/context/_common.types';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';

/**
 * Нотификация активных игр
 */
export const ActiveGamesNotification: React.FC = () => {
  const router = useRouter();

  const {
    state: { games, gamesData, uid },
  } = useLobbyContext();

  const activeGame: ILobbyContextGame | null = useMemo(() => {
    const game = Object.values(games).find((game) => {
      const currPh = game?.state?.currPh;
      const isUserGame =
        game.state?.cfg?.bPId === uid || game.state?.cfg?.wPId === uid;

      return (
        currPh !== ePhase.DONE && currPh !== ePhase.UNEXPECTED && isUserGame
      );
    });

    return game ? game : null;
  }, [games, uid]);

  const activeGameData = useMemo(() => {
    if (!activeGame?.state?.cfg?.gId) return null;

    const data = gamesData[activeGame.state.cfg.gId]?.boardData;

    return data ? data : null;
  }, [activeGame, gamesData]);

  if (!activeGame || !uid) return null;

  return (
    <div className={styles.active_board}>
      <ActiveBoardWidget
        playerUid={uid}
        board={activeGame}
        boardData={activeGameData}
        goGameClick={() => {
          router.push(`${routesConstants.GAME}/${activeGame.state?.cfg?.gId}`);
        }}
      />
    </div>
  );
};
