import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { RightArrowIcon, TextButton } from 'shared/atoms';
import { msToGameTime } from 'shared/helpers/_game';
import { getCountryById } from 'shared/helpers/_get_country_by_id';
import { useClassNames } from 'shared/hooks/_useClassNames.hook';
import { useCountdown } from '@utils/hooks/_useCountdown';
import { baseText, textColors } from 'shared/styles';
import { playerSide } from 'shared/types';

import { ePhase } from '@store/context/_common.types';
import { usePreMoveTime } from '@utils/hooks/_usePreMoveTime.hook';
import { IBoardPlayer } from './_types';
import {
  $Timer,
  $Player,
  $FullName,
  $Info,
  $InfoText,
} from './_BoardPlayer.styles';

/**
 * Информация об игроке
 * @param {IGamePlayer} player - инфа об игроке
 * @param {boolean} playerTurn - ходит ли сейчас игрок
 * @param {number} msLeft - сколько миллисекунд осталось у игрока
 * @param {string} boardId - id доски
 * @param {playerSide} side - цвет игрока
 * @param {IBoardWidgetLocalization} translation - локализация
 * @param {string} buttonClassName - внешний класс кнопки перехода к игре
 */
export const BoardPlayer: React.FC<IBoardPlayer> = ({
  player,
  msLeft,
  side,
  goGameClick,
  translation,
  currPh,
  buttonClassName,
  playerTurn,
  playerColor,
  whitePreMoveMsLeft,
  blackPreMoveMsLeft,
  timeControlMs,
}) => {
  const { milliseconds, updateTimer, startTimer, stopTimer } = useCountdown({
    timeInMilliseconds: 0,
    tickTime: 100,
    autostart: false,
  });

  const isPlayerMove = playerTurn === playerColor;

  const localization = {
    go_game: 'Go to game',
    ...translation,
  };

  const fullName = useMemo(() => {
    if (!player) return '';

    return player.full_name ? player.full_name : 'Anonymous';
  }, [player]);

  const preMoveMs = usePreMoveTime({
    playerColor,
    playerTurn,
    whitePreMoveMsLeft,
    blackPreMoveMsLeft,
  });

  useEffect(() => {
    if (preMoveMs && timeControlMs) {
      updateTimer(timeControlMs);
    } else {
      updateTimer(msLeft);
    }
  }, [timeControlMs, preMoveMs, msLeft]);

  useEffect(() => {
    if (currPh !== ePhase.PLAYING || preMoveMs) {
      stopTimer();
      return;
    }

    if (milliseconds && isPlayerMove) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [milliseconds, preMoveMs, isPlayerMove, currPh]);

  const playerClasses = useClassNames({
    top: side === playerSide.TOP,
    bottom: side === playerSide.BOTTOM,
    turn: isPlayerMove,
    opponent_turn: !isPlayerMove,
  });

  return (
    <$Player className={playerClasses}>
      {side === playerSide.TOP && <$FullName>{fullName}</$FullName>}

      <$Info>
        <$Timer>{msToGameTime(milliseconds)}</$Timer>

        {player && <$InfoText>{player.rating || 'New'}</$InfoText>}

        {player && player.nationality_id && (
          <$InfoText>
            {getCountryById(player.nationality_id)?.long_code}
          </$InfoText>
        )}
      </$Info>
      {side === playerSide.BOTTOM && (
        <$TextButton
          icon={RightArrowIcon}
          iconPosition="right"
          onClick={goGameClick}
          className={buttonClassName}
        >
          {localization.go_game}
        </$TextButton>
      )}
    </$Player>
  );
};

const $TextButton = styled(TextButton)`
  background: transparent;

  p {
    ${baseText({
      color: textColors['brown'],
      fontSize: '14px',
    })}
  }

  div {
    width: 6px;
    height: 12px;
    margin-top: 2px;
    margin-left: 4px;
  }
`;
