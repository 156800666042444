import React, { useMemo } from 'react';

import { useGameRoute } from '../hooks';
import { CustomHeader } from '@components/molecules/menus/CustomHeader';

export const HeaderWrapper: React.FC = () => {
  const showHeader = !useGameRoute();

  return useMemo(() => (showHeader ? <CustomHeader /> : null), [showHeader]);
};
