import { shapeColors, baseRow, baseText } from 'shared/styles';
import styled from 'styled-components';

export const $TimerWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 55px;
  width: 130px;
  height: 50px;
  z-index: 1001;
  background: ${shapeColors['coral']};
  border-radius: 4px;
  ${baseRow()}
`;

export const $Timer = styled.div`
  ${baseText({
    fontSize: '40px',
  })};
  font-feature-settings: 'tnum' on, 'lnum' on;
`;
