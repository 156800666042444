import { baseRow, baseText, textColors, baseColumn } from 'shared/styles';
import styled from 'styled-components';

export const $InfoText = styled.div`
  position: relative;
  ${baseRow('flex-start')};
  ${baseText({
    color: textColors['grey'],
    fontSize: '14px',
  })};
  line-height: 20px;

  &:not(:first-child) {
    &::before {
      content: '';
      display: block;
      position: relative;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: ${textColors['grey']};
      margin: 0 5px;
    }
  }
`;

export const $Timer = styled($InfoText)`
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const $Player = styled.div`
  position: relative;
  width: 100%;
  height: 47px;
  padding: 0 10px;
  ${baseColumn('flex-start')};
  justify-content: center;

  &.top {
    border-radius: 4px 4px 0 0;
  }

  &.bottom {
    border-radius: 0 0 4px 4px;
  }

  &.turn {
    background: rgba(24, 24, 24, 0.6);

    ${$Timer} {
      color: ${textColors['white']};
    }
  }

  &.opponent_turn {
    background: rgba(51, 51, 51, 0.6);
  }
`;

export const $FullName = styled.p`
  position: relative;
  width: 100%;
  ${baseText({
    fontSize: '14px',
  })}
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const $Info = styled.div`
  position: relative;
  width: 100%;
  ${baseRow('flex-start')};
`;
