import React from 'react';

import { ISmallBoardTimer } from './_types';
import { $TimerWrapper, $Timer } from './_styles';

/**
 * Компонент: обратный отсчет до старта на маленькой доске
 * @param {string} time - время
 * @param {string} className - внешний класс
 */
export const SmallBoardTimer: React.FC<ISmallBoardTimer> = ({
  time,
  className = '',
}) => {
  return (
    <$TimerWrapper className={className}>
      <$Timer>{time}</$Timer>
    </$TimerWrapper>
  );
};
