import { keyframes } from 'styled-components';
export const blinkAnimation = keyframes `
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;
