import { debounce } from '../helpers/_common';
import { useState, useEffect } from 'react';
/**
 * Хук для получения утверждения,
 * что ширина экрана установлена для мобильной версии,
 * при изменении размера экрана.
 */
export function useMobile(mobileWidth = 960) {
    const [mobileView, setMobileView] = useState(typeof window !== 'undefined' ? window.innerWidth <= mobileWidth : false);
    useEffect(() => {
        if (typeof window === 'undefined')
            return;
        function resize() {
            if (window.innerWidth <= mobileWidth) {
                setMobileView(true);
            }
            else {
                setMobileView(false);
            }
        }
        const debounceResize = debounce(resize, 200);
        window.addEventListener('resize', debounceResize);
        return () => window.removeEventListener('resize', debounceResize);
    }, []);
    return mobileView;
}
