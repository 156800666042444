import React from 'react';

import { ActiveGamesNotification } from '@components/molecules/notifications/_ActiveGamesNotification';
import { useGameRoute } from '../hooks';

export const ActiveGamesNotificationWrapper: React.FC = () => {
  const showActiveBoard = !useGameRoute();

  return showActiveBoard ? <ActiveGamesNotification /> : null;
};
