import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Стрелка вправо
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const RightArrowIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 17 31", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M2.1 0L17 15.5 2.1 31 0 29l12.9-13.5L0 2.1 2.1 0z", fill: fill })));
