import React from 'react';
import { Preloader } from '../../preloaders';
import { LightningIcon } from '../../../atoms';
import { $Container, $Row, $Title, $Text } from './_styles';
export const OfflineNotification = ({ style, reconnecting = false }) => {
    return (React.createElement($Container, { style: style },
        React.createElement($Row, null,
            React.createElement($Title, null, reconnecting ? 'Reconnecting...' : 'Offline'),
            React.createElement(Preloader, { style: { position: 'relative', width: '35px' }, size: "35", icon: React.createElement(LightningIcon, { className: "on-icon", width: 8 }) })),
        React.createElement($Text, null, "You\u00A0will not receive tournament alerts, personal game invitations, and chat\u00A0messages")));
};
