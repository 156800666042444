import React from 'react';
import { $Icon, $Text, $Button } from './_styles';
/**
 * Текстовая кнопка (может быть с иконкой)
 * @param {ReactNode} children - текст кнопки
 * @param {ReactNode} icon - копонент иконки
 * @param {string} iconPosition - определяет позицию иконки
 * @param {string} iconSize - определяет размер иконки
 * @param {string} textSize - определяет размер текста
 * @param {string} className - внешний класс
 * @param {string} iconClassName - внешний класс иконки
 * @param {string} textClassName - внешний класс текста
 * @param {string} link - ссылка (внутренняя или внешняя)
 * @param {ReactComponent} LinkWrapper - компонент обертка ссылки
 */
export const TextButton = React.memo(({ children, icon = null, iconPosition = 'left', iconSize = 'small', textSize = 'big', className = '', iconClassName = '', textClassName = '', type, disabled, style, onClick = () => { }, }) => {
    const buttonContent = (React.createElement(React.Fragment, null,
        icon && (React.createElement($Icon, { className: iconClassName, size: iconSize }, React.createElement(icon))),
        React.createElement($Text, { className: textClassName, size: textSize }, children)));
    return (React.createElement($Button, { type: type, className: className, iconPosition: iconPosition, onClick: onClick, disabled: disabled, style: style }, buttonContent));
});
