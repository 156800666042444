import styled from 'styled-components';
import { screenSizes, shapeColors } from '../../../../styles';
import { blinkAnimation } from '../_common.styles';
export const $Container = styled.div `
  height: 140px;
  width: 240px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 10px 16px 8px;
  background-color: ${shapeColors.grey};

  .on-icon {
    animation: ${blinkAnimation} 1.5s linear infinite;
  }

  @media ${screenSizes.PHONE} {
    width: 220px;
  }
`;
export const $Row = styled.div `
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const $Title = styled.p `
  font-family: 'Gerbera';
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  color: white;
`;
export const $Text = styled.div `
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: white;
  opacity: 0.6;
  width: 188px;
`;
