import React, { memo } from 'react';

import Footer from '@chessarena/components/lib/layout/Footer';

import { useCustomFooterData } from './_useCustomFooterData.hook';

export const CustomFooter: React.FC = memo(function CustomFooter() {
  const {
    navigation,
    links,
    socialNetworks,
    copyrights,
    appsDownload,
    localization,
  } = useCustomFooterData();

  return (
    <Footer
      navigation={navigation}
      links={links}
      socialNetworks={socialNetworks}
      copyrights={copyrights}
      appsDownload={appsDownload}
      localization={localization}
    />
  );
});
