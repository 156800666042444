import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { SelectPopupRenderItemLinkParams } from '@chessarena/components/lib/general/SelectPopup/types';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { LocaleType } from 'shared/types';
import { useApplicationContext } from '@application';
import {
  footerAppsDownload,
  footerCopyrights,
  footerLinks,
  footerSocialNetworks,
  footerLocalization,
  footerNavigation,
} from './_constants';
import { useCustomFooterDataPlayChess } from './_useCustomFooterDataPlayChess.hook';

export const useCustomFooterData = () => {
  const { localization: l, locale, changeLocale } = useApplicationContext();

  const { asPath } = useRouter();

  const playChess = useCustomFooterDataPlayChess();

  const renderLink = (params: RenderLinkParams) => {
    const { link, children } = params;

    return <Link href={link}>{children}</Link>;
  };

  const navigation = useMemo(
    () => ({
      items: footerNavigation.items.map((item) => ({
        ...item,
        title: l?.footer.navigation.items[item.id],
      })),
      renderLink,
    }),
    []
  );

  const links = useMemo(
    () => ({
      ...footerLinks,
      title: l.footer.links.title,
      items: footerLinks.items.map((item) => ({
        ...item,
        title: l?.footer.links.items[item.id],
        link: item.link ? (
          <Link href={item.link} prefetch={false}>
            {l?.footer.links.items[item.id]}
          </Link>
        ) : undefined,
      })),
    }),
    [l]
  );

  const socialNetworks = useMemo(
    () => ({
      ...footerSocialNetworks,
      description: l.footer.social_networks.description,
      items: footerSocialNetworks.items.map((item) => ({
        ...item,
        title: l?.footer.social_networks.items[item.id],
      })),
    }),
    [l]
  );

  const copyrights = useMemo(
    () => ({
      ...footerCopyrights,
      bureau: {
        ...footerCopyrights.bureau,
        description: l?.footer?.copyrights?.bureau.description,
      },
      worldchess: {
        ...footerCopyrights.worldchess,
        copyright: l?.footer.copyrights.worldchess.copyright,
        links: footerCopyrights.worldchess.links.map((item) => ({
          ...item,
          title: l?.footer.copyrights.worldchess.links[item.id],
        })),
      },
    }),
    [l]
  );

  const appsDownload = useMemo(
    () => ({
      ...footerAppsDownload,
      description: l?.footer.appsDownload.description,
    }),
    [l]
  );

  const renderItemLink = (params: SelectPopupRenderItemLinkParams) => {
    const { link, id, children, ...rest } = params;

    return (
      <Link href={link} locale={id} {...rest}>
        {children as React.ReactNode}
      </Link>
    );
  };

  const localization = useMemo(
    () => ({
      ...footerLocalization,
      items: footerLocalization.items.map((item) => ({
        ...item,
        title: l?.localization[item.id].title,
        abbr: l?.localization[item.id].abbr,
        link: asPath,
      })),
      active: locale,
      setActive: (locale: string) => {
        changeLocale(locale as LocaleType);
      },
      renderItemLink,
    }),
    [l, locale, changeLocale, asPath]
  );

  return {
    navigation,
    links,
    socialNetworks,
    copyrights,
    appsDownload,
    localization,
    playChess,
  };
};
