import React, { useMemo } from 'react';

import { useGameRoute } from '../hooks';
import { CustomFooter } from '@components/molecules/menus/CustomFooter';

export const FooterWrapper: React.FC = () => {
  const isGameRoute = useGameRoute();

  const showFooter = useMemo(() => {
    return !isGameRoute;
  }, [isGameRoute]);

  return showFooter ? <CustomFooter /> : null;
};
