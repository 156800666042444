import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOnline } from 'shared/hooks/_useOnline.hook';
import { OfflineNotification } from 'shared/molecules';
import { SocketStatus } from 'shared/types';
import { useGameRoute } from '../hooks';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';

const OFFLINE_BADGE_SHOW_DELAY = 3000;

export const ConnectionIssuesWrapper: React.FC = () => {
  // const anotherBrowserLogin = useWebSocketStore('another_browser_login');

  const {
    state: { wsStatus },
  } = useLobbyContext();

  const isConnectedToInternet = useOnline();

  const isGameRoute = useGameRoute();

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [showOfflineBadge, setShowOfflineBadge] = useState(false);

  const isOffline = useMemo(() => {
    return !isConnectedToInternet || wsStatus === SocketStatus.DISCONNECTED;
  }, [wsStatus, isConnectedToInternet]);

  const isReconnecting = useMemo(() => {
    return wsStatus === SocketStatus.RECONNECTING;
  }, [wsStatus]);

  useEffect(() => {
    if (isOffline) {
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          setShowOfflineBadge(true);
        }, OFFLINE_BADGE_SHOW_DELAY);
      }

      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    setShowOfflineBadge(false);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isOffline]);

  if (isGameRoute) return null;

  // NOTE: пока отключаем, т.к. в лобби сокетах нет ограничений на подключения
  // if (anotherBrowserLogin)
  //   return (
  //     <ExternalClosingPopup
  //       showPopup
  //       clickAreaStyles={{ zIndex: 1010 }}
  //       component={
  //         <DisconnectedNotification
  //           style={{ position: 'fixed', top: 0, right: 0, zIndex: 10000 }}
  //           onReconnectButtonClick={() => window.location.reload()}
  //         />
  //       }
  //     ></ExternalClosingPopup>
  //   );

  if (showOfflineBadge || isReconnecting)
    return (
      <OfflineNotification
        reconnecting={isReconnecting}
        style={{ position: 'fixed', top: '0', right: '0', zIndex: 1001 }}
      />
    );

  return null;
};
