import { useMemo } from 'react';
import { generateClassNames } from '../helpers/_generate_classNames';
/**
 * Хук для конкатенации классов CSS. Принимает на вход:
 * @param {object} classes  - ключ: название класса, значение: истинность (активный ли класс).
 * @returns {string} result - строка активных классов
 */
export function useClassNames(classes) {
    const classNames = useMemo(() => generateClassNames(classes), [classes]);
    return classNames;
}
