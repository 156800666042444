import {
  baseColumn,
  shapeColors,
  textColors,
  screenSizes,
  baseText,
} from 'shared/styles';
import styled from 'styled-components';

export const $Board = styled.div`
  position: relative;
  width: 164px;
  ${baseColumn('flex-start')};
  border-radius: 3px;
  background: ${shapeColors.grey_dark};
  cursor: pointer;

  &:hover {
    .go_game_button {
      p {
        color: ${textColors.white};
        transition: 0s;
      }

      path {
        fill: ${textColors.white};
        transition: 0s;
      }
    }
  }
`;

export const $ChessboardWrapper = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  align-self: center;

  @media ${screenSizes.MOBILE} {
    display: none;
  }
`;

export const $FullName = styled.p`
  position: relative;
  width: 100%;
  ${baseText({ fontSize: '14px' })}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const $Timer = styled.div`
  ${baseText({ fontSize: '40px' })};
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const $TournamentMobile = styled($Board)`
  justify-content: space-between;
  padding: 3px 10px 6px 10px;
  background: rgba(51, 51, 51, 0.6);
  cursor: pointer;

  ${$Timer} {
    color: ${textColors.coral};
  }

  &:hover {
    .go_game_button {
      p {
        color: ${textColors.white};
        transition: 0s;
      }

      path {
        fill: ${textColors.white};
        transition: 0s;
      }
    }
  }
`;
