import { useState, useEffect } from 'react';
/**
 * @description Следит за состоянием подключения устройства пользователя к сети
 *
 * @returns [boolean] - status - Состояние подключения
 */
export const useOnline = () => {
    const [status, setStatus] = useState(typeof window !== 'undefined' ? navigator.onLine : false);
    const setOffline = () => {
        setStatus(false);
    };
    const setOnline = () => {
        setStatus(true);
    };
    useEffect(() => {
        if (typeof window === 'undefined')
            return;
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);
    return status;
};
